export const cardContentTradImplantMixin = {
  computed: {
    cardContentTradImplant () {
      let cardContentTradImplant = []
      switch (this.$i18n.locale) {
        case 'en':
          cardContentTradImplant = this.$i18n.messages.en.cardContentTrad
          break
        case 'ru':
          cardContentTradImplant = this.$i18n.messages.ru.cardContentTrad
          break
        default:
          cardContentTradImplant = this.$i18n.messages.fr.cardContentTrad
      }
      return Object.values(cardContentTradImplant)
        .filter(cardContent => cardContent)
    }
  }
}

import { render, staticRenderFns } from "./TraditionalDentalImplant.vue?vue&type=template&id=2cf68049&scoped=true"
import script from "./TraditionalDentalImplant.vue?vue&type=script&lang=js"
export * from "./TraditionalDentalImplant.vue?vue&type=script&lang=js"
import style0 from "./TraditionalDentalImplant.vue?vue&type=style&index=0&id=2cf68049&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cf68049",
  null
  
)

/* custom blocks */
import block0 from "@/locales/traditional_dental_implant.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fdental-implant%2Ftraditional-dental-implant%2FTraditionalDentalImplant.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/articles-card-content.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fdental-implant%2Ftraditional-dental-implant%2FTraditionalDentalImplant.vue&external"
if (typeof block1 === 'function') block1(component)
import block2 from "@/locales/card-content-trad-implant.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fdental-implant%2Ftraditional-dental-implant%2FTraditionalDentalImplant.vue&external"
if (typeof block2 === 'function') block2(component)

export default component.exports
<i18n src="@/locales/traditional_dental_implant.json" />
<i18n src="@/locales/articles-card-content.json" />
<i18n src="@/locales/card-content-trad-implant.json" />

<template>
  <div class="trad-implant">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <section class="section">
      <div class="columns is-gapless is-multiline">
        <div
          v-for="content in cardContentTradImplant"
          :key="content.id"
          class="column is-half-tablet is-one-quarter-widescreen is-one-quarter-fullhd"
        >
          <ImanRoundedPictureBox
            :content="content"
            :service-class="roundedPicsClass"
          >
            {{ $t('more_info') }}
          </ImanRoundedPictureBox>
        </div>
      </div>
    </section>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <ImanH3>{{ $t('h3_title_1') }}</ImanH3>
        <p>
          <span v-html="$t('article_p1_1')" />
        </p>
        <p>
          <span v-html="$t('article_p1_2')" />
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[1]">
        <ImanH3>{{ $t('h3_title_2') }}</ImanH3>
        <p>
          {{ $t('article_p2') }}
        </p>
        <ImanH3>{{ $t('h3_title_3') }}</ImanH3>
        <p>
          {{ $t('article_p3') }}
        </p>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section class="columns is-centered background-section">
      <div class="column is-8">
        <ImanCollapsableCard :title="$t('collapse_title_1')">
          <p>
            {{ $t('collapse_p1_1') }}
          </p>
          <p>
            <span v-html="$t('collapse_p1_2')" />
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_2')">
          <p>
            {{ $t('collapse_p2') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_1')">
            {{ $t('more_info_1') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_3')">
          <p>
            {{ $t('collapse_p3') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_2')">
            {{ $t('more_info_2') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_4')">
          <p>
            {{ $t('collapse_p4') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_3')">
            {{ $t('more_info_3') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_5')">
          <p>
            {{ $t('collapse_p5_1') }}
          </p>
          <p>
            <span v-html="$t('collapse_p5_2')" />
          </p>
          <ImanMoreInfo :path="$t('more_info_path_4')">
            {{ $t('more_info_4') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
      </div>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import ImanRoundedPictureBox from "@/components/ImanRoundedPictureBox";
  import ImanMoreInfo from "@/components/ImanMoreInfo";
  import ImanArticleSection from "@/components/ImanArticleSection";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import {cardContentTradImplantMixin} from "@/mixins/card-content-trad-implant-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'TraditionalDentalImplant',
    components: {
      ImanMoreInfo,
      ImanArticleSection,
      ImanRoundedPictureBox
    },
    mixins: [
      articlesCardContentFilterMixin,
      cardContentTradImplantMixin,
      metaMixin
    ],
    data () {
      return {
        mainBackgroundClass: 'none-background',
        articlesCardContentId: [22, 2111, 61, 2242],
        roundedPicsClass: {
          overlay: 'overlay-half-circle show-overlay-half-circle',
          element: 'element show-element'
        },
        img: [
          {
            path: 'dental-implant/trad-implant/partie_implant_dentaire_pilier_vis.jpg',
            position: 'right',
            alt: 'Qu\'est ce qu\'un implant dentaire et ses différents éléments'
          },
          {
            path: 'dental-implant/trad-implant/forme-taille-implant-dentaire.jpg',
            position: 'right',
            alt: 'quel implant dentaire choisir'
          },
        ]
      }
    },
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../assets/img/thumbnail/MINIATURE_2.1.png')
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
        }
        if (document.body.scrollTop > 1090 || document.documentElement.scrollTop > 1090) {
          this.mainBackgroundClass = 'fixed-background'
        }
        if (document.body.scrollTop > 3600 || document.documentElement.scrollTop > 3600) {
          this.mainBackgroundClass = 'none-background'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-background {
  @media only screen and (min-width: 768px) {
    /* tablet et ordinateurs */
    background-image: url("../../../assets/img/dental-implant/trad-implant/h1-implant-trad-d.jpg");
  }
  @media only screen and (max-width: 767px) {
    /* smartphone en mode paysage */
    background-image: url("../../../assets/img/dental-implant/h1-implant-dentaire-t.jpg");
  }
  @media only screen and (max-width: 767px) and (orientation: portrait) {
     /* smartphone en mode portait */
    background-image: url("../../../assets/img/dental-implant/h1-implant-dentaire-m.jpg");
  }
  }
</style>
